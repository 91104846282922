export const IS_MAC = !!window.navigator.platform.match(/^Mac/);

export const Modifier = {
  CTRL: IS_MAC ? '⌘' : 'Ctrl',
  SHIFT: IS_MAC ? '⇧' : 'Shift',
  ALT: IS_MAC ? '⌥' : 'Alt',
  CLICK: 'Click',
};

// Used with KeyboardEvent.key because KeyboardEvent.keyCode is deprecated.
export const KEY = {
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
};

export function ctrlOrCmdKeyPressed(
  event: MouseEvent | React.MouseEvent | KeyboardEvent | React.KeyboardEvent,
): boolean {
  return IS_MAC ? event.metaKey : event.ctrlKey;
}

export const KEYCODE = {
  BACKSPACE: 8,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ESC: 27,
  SPACE: 32,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  SQUARE_BRACKET_LEFT: 219,
  SQUARE_BRACKET_RIGHT: 221,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  H: 72,
  I: 73,
  K: 75,
  L: 76,
  M: 77,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  Y: 89,
  Z: 90,
  SLASH: 191,
  BACKSLASH: 220,
};

const KEYSTRING = {
  13: 'Enter',
  17: 'Control',
  27: 'Esc',
  32: 'Space',
  37: 'Left Arrow',
  38: 'Up Arrow',
  39: 'Right Arrow',
  40: 'Down Arrow',
  191: '/',
  220: '\\',
};

export function keyCodeToString(keyCode: number): string {
  return (
    (KEYSTRING as any)[keyCode] ||
    String.fromCharCode(keyCode >= 96 && keyCode <= 105 ? keyCode - 48 : keyCode)
  );
}
