import React from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';

import './icon.scss';

const LEGACY_ICON_COLORS = ['default', 'dim', 'primary', 'text'];
const DEFAULT_ICON_SIZE_PX = 14;

export interface IconProps {
  src: string;
  className?: string;
  color?: string;
  height?: number;
  width?: number;
  onLoad?: () => void;
  /**
   * @deprecated
   * Prefer to not use this style to override any CSS properties, since doing so
   * may result in unexpected behavior. This prop is provided for backwards compatibility
   * with the legacy Icon component.
   */
  deprecatedStyle?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const {
    height = DEFAULT_ICON_SIZE_PX,
    src,
    color,
    className,
    width,
    onLoad,
    deprecatedStyle,
  } = props;
  const useLegacyColorClass = color && LEGACY_ICON_COLORS.includes(color);
  return (
    <SVG
      className={classNames('dragon-fruit-icon icon', className, {
        // TODO(youngxguo): deprecate this in favor of using the color prop.
        // Legacy icons have a fill="none" attribute within the svgs themselves
        // that is not overridable via style, but these should be cleaned up
        // so we can trust the color prop.
        [`dragon-fruit-icon--legacy-${color}`]: useLegacyColorClass,
      })}
      cacheGetRequests
      style={{
        height: height,
        minWidth: height,
        fill: useLegacyColorClass ? undefined : color,
        width: width,
        ...deprecatedStyle,
      }}
      src={src ?? ''}
      onLoad={onLoad}
    />
  );
};

export default Icon;
