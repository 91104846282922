// From https://stackoverflow.com/a/36760050.
const IPV4_REGEX = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
// From https://stackoverflow.com/a/17871737.
const IPV6_REGEX =
  /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;

export function setCookie(key: string, value: string, daysExpr: number): void {
  const date = new Date();
  date.setTime(date.getTime() + daysExpr * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  const server = window.location.origin;
  const link = document.createElement('a');
  link.href = server;

  // Clear cookies without explicit domain set
  // TODO(Vikas): remove in 1.32 after cookies domains are updated
  document.cookie = `${key}=""; expires=${new Date().toUTCString()}; path=/`;

  // Clear old web service cookie since domain has changed.
  // Otherwise, we will have old login cookies stuck in browsers keeping the user
  // logged in - even when they try to log out.
  if (link.hostname.endsWith('.applied.co')) {
    document.cookie = `${key}=""; expires=${new Date().toUTCString()}; path=/; domain=.${
      link.hostname
    }`;
  }

  // Set cookie with leading dot on hostname so that cookies are sent on requests
  // to subdomains (primarily grpc.hostname)
  const cookieDomain = getCookieDomainFromHostname(link.hostname);
  document.cookie = `${key}=${value}${expires};path=/;domain=${cookieDomain}`;
}

export function getCookieDomainFromHostname(hostname: string): string {
  // Local host is a special domain - setting subdomain access via a prefix period is invalid.
  if (hostname === 'localhost' || IPV4_REGEX.test(hostname) || IPV6_REGEX.test(hostname)) {
    return hostname;
  }

  if (hostname.endsWith('.applied.co')) {
    // For applied.co subdomains, we want to preserve auth across the whole domain rather than
    // only subdomains for the host.
    // For example - if you authenticate on `home.applied.co` it's preferable to stay authenticated on `issue-tracker.applied.co`.
    //
    // NOTE: This logic may apply reasonably to customer domains, but due to lack of clarity
    // we special case here for applied.co.
    const domain_segments = hostname.split('.').reverse();
    const root_domain = domain_segments[1] + '.' + domain_segments[0];
    // Prefix period guarantees all subdomains match the cookie.
    return '.' + root_domain;
  } else if (hostname.endsWith('sandbox.kibbles.applied.dev')) {
    // For frontend sanxboxes, we want to make sure auth is preserved in requests to kibbles.applied.dev,
    // since no backend is run on the sandbox itself.
    return '.kibbles.applied.dev';
  } else {
    // Prefix period guarantees subdomains match the cookie. This is particularly helpful
    // for authenticating gRPC-Web traffic.
    return '.' + hostname;
  }
}

export function getCookie(name: string): string | undefined {
  const cookieSearch = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return cookieSearch ? cookieSearch[1] : undefined;
}

type AuthTokenInfo = {
  email?: string;
  edit_permissions?: string;
  enable_admin_page?: boolean;
  enable_cluster_status_dashboard?: boolean;
  enable_issue_tracking?: boolean;
  disabled_product_manuals?: string[];
  product_access_list?: string[];
  userGroups?: string[];
  uuid?: string;
};

// Exported for test only.
export function getInfoFromAuthTokenCookie(): AuthTokenInfo | undefined {
  const authToken = getCookie('auth_token');
  if (authToken) {
    try {
      // The JWT cookie contains three parts separated by '.'. The second contains a
      // JSON of the info, so we need to split it then parse it here
      const parsed = JSON.parse(atob(authToken.split('.')[1]));
      return parsed;
    } catch (e) {
      console.error('Failed to parse auth token cookie', e);
    }
  }
}

export function getEmailFromAuthTokenCookie(): string {
  const info = getInfoFromAuthTokenCookie();
  return info?.email ?? '';
}

export function getUuidFromAuthTokenCookie(): string {
  const info = getInfoFromAuthTokenCookie();
  return info?.uuid ?? '';
}

export function getUserIdentificationFromAuthTokenCookie(): string[] {
  const info = getInfoFromAuthTokenCookie();
  const userGroups = info?.userGroups ?? [];
  const emails = info?.email ? [info.email] : [];
  return [...emails, ...userGroups];
}

export function getDomainFromAuthTokenCookie(): string {
  try {
    const email = getEmailFromAuthTokenCookie();
    const domain = email.split('@')[1];
    return domain ?? '';
  } catch (e) {
    return '';
  }
}

export function getEditPermissionsFromAuthTokenCookie(): string {
  const info = getInfoFromAuthTokenCookie();
  return info?.edit_permissions ?? '';
}

/**
 * @returns A list of all licenced products for the current user. Product names are lower-cased.
 */
export function getProductAccessControlListFromAuthTokenCookie(): string[] {
  const info = getInfoFromAuthTokenCookie();
  return info?.product_access_list ?? [];
}

/**
 * @returns A boolean if the admin page is enabled in the auth token
 */
export function getEnableAdminPageFromAuthTokenCookie(): boolean {
  const info = getInfoFromAuthTokenCookie();
  return info?.enable_admin_page ?? false;
}

/**
 * @returns A boolean if the cluster status page is enabled in the auth token
 */
export function getEnableClusterStatusDashboardFromAuthTokenCookie(): boolean {
  const info = getInfoFromAuthTokenCookie();
  return info?.enable_cluster_status_dashboard ?? false;
}

export function getCompanyEnabledIssueTracking(): boolean {
  const info = getInfoFromAuthTokenCookie();
  return info?.enable_issue_tracking ?? false;
}

/**
 * @returns A list of all disabled product manuals for a given customer. Product names are lower-cased.
 */
export function getDisabledProductManualsFromAuthTokenCookie(): string[] {
  const info = getInfoFromAuthTokenCookie();
  return info?.disabled_product_manuals ?? [];
}

export function isAppliedUser(): boolean {
  return getEmailFromAuthTokenCookie().endsWith('applied.co');
}

export function isEchelonUser(): boolean {
  return getEmailFromAuthTokenCookie().endsWith('echelon.dev');
}
