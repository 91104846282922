import type { ReactElement } from 'react';
import React, { forwardRef, useRef } from 'react';
import classNames from 'classnames';

import './action-button.scss';

import Icon from '../Icon/Icon';
import ConditionalLink from '../Link/ConditionalLink';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import BUTTON_CSS from './constants';

const ICON_SIZE = 14;

export type InternalActionButtonAppearance = 'section-button';
export type ActionButtonAppearance = 'default' | 'primary' | 'invisible' | 'destructive';
export type ActionButtonSize = 'small' | 'medium' | 'large';
export type ButtonProps = Omit<RootButtonProps, 'contentColor'>;

interface RootButtonProps {
  appearance?: ActionButtonAppearance | InternalActionButtonAppearance;
  size?: ActionButtonSize;
  disabled?: boolean;
  selected?: boolean;
  shouldFillContainer?: boolean;
  iconBeforeSrc?: string;
  iconAfterSrc?: string;
  onIconLoad?: () => void;
  fixedWidth?: number;
  className?: string;
  onFilesChange?: (file: FileList) => void;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  ariaLabel?: string;
  /**
   * Link to redirect to. Prefer setting `link` over specifying an `onClick` for links because
   * `link` will support right clicking to open in a new tab.
   *
   * Relative links (determined by whether the input starts with "/") will open in the current tab.
   * Absolute links will open in a new tab by default.
   */
  link?: string;
  loading?: boolean;
  contentColor?: string;
}

export const InternalActionButton = forwardRef(function InternalActionButton(
  {
    appearance = 'default',
    size = 'medium',
    shouldFillContainer = false,
    contentColor,
    iconBeforeSrc,
    iconAfterSrc,
    onIconLoad,
    fixedWidth,
    className = '',
    children,
    disabled: _disabled,
    selected,
    onFilesChange,
    ariaLabel,
    link,
    loading,
    ...otherProps
  }: RootButtonProps,
  ref: React.Ref<HTMLElement>,
): ReactElement<any, any> {
  const disabled = _disabled || loading;

  const props = {
    className: classNames(
      BUTTON_CSS.ROOT,
      className,
      `action-button-${appearance}`,
      `action-button-${size}`,
      {
        [BUTTON_CSS.SHOULD_FILL_CONTAINER]: shouldFillContainer,
        [BUTTON_CSS.DISABLED]: disabled,
        [BUTTON_CSS.SELECTED]: selected && !disabled,
      },
    ),
    ...otherProps,
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // Setting the color via the style prop to keep the icon color and text color consistent.
  const style: React.CSSProperties = {};
  if (contentColor) {
    style.color = contentColor;
  }

  return (
    <ConditionalLink link={link} disabled={disabled} shouldFillContainer={shouldFillContainer}>
      <button
        aria-label={ariaLabel}
        type="button"
        ref={ref as any}
        {...props}
        onClick={(evt): void => {
          if (!disabled) {
            props.onClick?.(evt);
            if (onFilesChange) {
              inputRef.current?.click();
            }
          }
        }}
        data-testid="action-button"
      >
        {onFilesChange && (
          <input
            type="file"
            data-testid="button-file-input"
            ref={inputRef}
            onChange={(evt): void => {
              if (!evt.target.files?.length) {
                return;
              }
              onFilesChange(evt.target.files);
            }}
            multiple
          />
        )}
        {loading && <LoadingSpinner size={ICON_SIZE} shouldCenterVertically />}
        {iconBeforeSrc && fixedWidth && (
          <Icon src={iconBeforeSrc} height={ICON_SIZE} width={fixedWidth} onLoad={onIconLoad} />
        )}
        {iconBeforeSrc && !fixedWidth ? (
          <Icon src={iconBeforeSrc} height={ICON_SIZE} color={contentColor} onLoad={onIconLoad} />
        ) : null}
        <div className="children-wrapper" style={style}>
          {children}
        </div>
        {iconAfterSrc ? (
          <Icon src={iconAfterSrc} height={ICON_SIZE} color={contentColor} onLoad={onIconLoad} />
        ) : null}
      </button>
    </ConditionalLink>
  );
});

// The Default Button does not allow an iconAfter
const ActionButton = (props: ButtonProps): ReactElement<any, any> => {
  const { ...otherProps } = props;
  return (
    <InternalActionButton
      {...{
        ...otherProps,
      }}
    />
  );
};

export default ActionButton;
